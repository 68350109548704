<template>
  <div class="table-responsive mb-0">
    <table class="table table-hover">
      <tbody>
        <tr>
          <th class="col-shrink">
            {{ translateAttribute("document", "title") }}
          </th>

          <td>{{ document.title }}</td>
        </tr>

        <tr>
          <th class="col-shrink">
            {{ translateAttribute("document", "created_at") }}
          </th>

          <td>{{ $d(parseDate(document.created_at), "dateTime") }}</td>
        </tr>

        <tr>
          <th class="col-shrink">
            {{ translateAttribute("document", "filesize") }}
          </th>

          <td>{{ document.file_size }}</td>
        </tr>

        <tr>
          <th class="col-shrink">
            {{ translateAttribute("document", "uploaded_by") }}
          </th>

          <td>{{ getUserName(document.uploaded_by_id) }}</td>
        </tr>

        <tr>
          <th class="col-shrink">
            {{ $t("activerecord.models.folder.one") }}
          </th>

          <td v-if="document.folder?.id">
            <be-link :href="document.folder.paths.base">
              {{ document.folder.title }}
            </be-link>
          </td>

          <td v-else>
            <be-link :href="document.paths.index">
              {{ $t("activerecord.models.document.other") }}
            </be-link>
          </td>
        </tr>

        <tr v-if="document.bolagsverket_checksum">
          <th class="col-shrink">
            {{ translateAttribute("document", "bolagsverket_checksum") }}
          </th>

          <td>{{ document.bolagsverket_checksum }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "DocumentMetaData",

  props: {
    document: {
      type: Object,
      required: true,
    },
  },
};
</script>
