<template>
  <signature-case>
    <template #title>
      <h5 class="card-title">
        {{ title }}

        <div v-if="localDocument.meeting_information" class="small">
          {{ $t("activerecord.attributes.material.minutes") }}
        </div>
      </h5>
    </template>

    <template #header-buttons>
      <signature-case-buttons :document="localDocument" />
    </template>

    <template #metadata>
      <signature-case-metadata :document="localDocument" class="mb-4" />
    </template>

    <template #table>
      <h6 class="h5">
        {{ $t("components.documents.signature_form.internal_signatories") }}
      </h6>

      <signature-table
        :signatures="preparedSignatures"
        :allowed-to-remind="allowedToRemind"
        :loading-signature-ids="loadingSignatureIds"
        @remind="sendDocumentSignatureReminder"
      />
    </template>
  </signature-case>
</template>

<script>
import SignatureHelpers from "@/mixins/signatureHelpers";

import SignatureCase from "@/components/shared/SignatureCase.vue";
import SignatureTable from "@/components/shared/SignatureTable.vue";

import SignatureCaseButtons from "./SignatureCaseButtons.vue";
import SignatureCaseMetadata from "./SignatureCaseMetadata.vue";

export default {
  components: {
    SignatureCase,
    SignatureCaseButtons,
    SignatureCaseMetadata,
    SignatureTable,
  },

  mixins: [SignatureHelpers],

  props: {
    document: {
      type: Object,
      required: true,
    },

    overrideTitle: {
      type: String,
      default: "",
    },

    loadingSignatureIds: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      localDocument: this.cloneDeep(this.document),
    };
  },

  computed: {
    title() {
      if (this.overrideTitle) {
        return this.overrideTitle;
      }

      if (this.localDocument.meeting_information) {
        return `${this.localDocument.meeting_information.number} ${this.localDocument.meeting_information.title}`;
      }

      return this.localDocument.filename;
    },

    allowedToRemind() {
      return this.localDocument.signatures.some(
        (signature) => signature.policy?.remind
      );
    },

    hasRejectedSignatures() {
      return this.localDocument.signatures.some(
        (signature) => signature.rejected_at
      );
    },

    // Transform to more generic format to match SignatureTable component
    // which also works for Meeting-attendances.
    preparedSignatures() {
      return this.localDocument.signatures.map((signature) =>
        this.documentPrepareSignature(this.localDocument, signature)
      );
    },
  },

  watch: {
    document: {
      handler() {
        this.localDocument = this.cloneDeep(this.document);
      },

      deep: true,
    },
  },
};
</script>
