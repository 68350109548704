<template>
  <div
    v-show="show"
    :class="`alert alert-${variant}`"
    role="alert"
    aria-live="polite"
    aria-atomic="true"
  >
    <div class="row no-gutters">
      <div class="col-auto d-flex align-items-start mr-1">
        <be-spinner v-if="loading" class="mr-1" :variant="variant" />

        <i v-else-if="iconClass" class="mr-1 fa-lg" :class="iconClass" />
      </div>

      <div class="col p-0">
        <slot></slot>
      </div>

      <div v-if="dismissable" class="col-auto">
        <button
          class="close"
          type="button"
          :aria-label="$t('buttons.titles.close')"
          @click="$emit('dismiss')"
        >
          <i class="fas fa-2xs fa-times" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeAlert",

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    show: {
      type: [Boolean, Number, String],
      required: false,
      default: true,
    },

    dismissable: {
      type: Boolean,
      required: false,
      default: false,
    },

    variant: {
      type: String,
      required: false,
      default: "info",

      validator: (value) => {
        return ["info", "warning", "danger", "success"].includes(value);
      },
    },
  },

  emits: ["dismiss"],

  computed: {
    iconClass() {
      let iconClass = "";

      switch (this.variant) {
        case "info":
          iconClass = "fas fa-circle-info";
          break;
        case "warning":
          iconClass = "fa-duotone fa-triangle-exclamation";
          break;
        case "danger":
          iconClass = "fas fa-hexagon-exclamation";
          break;
        case "success":
          iconClass = "fas fa-circle-check";
          break;
        default:
          iconClass = "";
      }

      return iconClass;
    },
  },
};
</script>
