/**
 * Enables fixed sticky table headers for tables with the `data-sticky-header="true"` attribute.
 *
 * This method:
 * - Clones the original <thead> to act as a placeholder to preserve layout height.
 * - Converts the original <thead> to position: fixed when it scrolls out of view.
 * - Dynamically syncs column widths between placeholder and sticky header.
 * - Reverts to normal header when scrolled back up or past the table.
 */

export const enableStickyTableHeaders = () => {
  const tables = document.querySelectorAll(
    "table.table[data-sticky-header='true']"
  );

  tables.forEach((table) => {
    const parent = table.closest(".table-responsive");
    if (!parent) return;

    // Don't enable sticky headers if the table has horizontal scroll
    if (parent.scrollWidth > parent.clientWidth) return;

    // Prevent double-initialization
    if (table.dataset.stickyInit === "true") return;
    table.dataset.stickyInit = "true";

    const thead = table.querySelector("thead");
    const tbody = table.querySelector("tbody");
    if (!thead || !tbody) return;

    // Clone <thead> to act as a hidden placeholder to preserve layout when original thead is fixed
    const placeholder = thead.cloneNode(true);
    placeholder.classList.add("placeholder");
    table.insertBefore(placeholder, tbody);

    // Cache the vertical offset of the <thead> from the top of the page
    const theadOffsetTop = thead.getBoundingClientRect().top + window.scrollY;

    /**
     * Sync column widths from placeholder to sticky <thead>
     * This is necessary when using position: fixed, as it removes <thead> from table layout.
     */
    const syncColumnWidths = () => {
      const originalThs = placeholder.querySelectorAll("th");
      const stickyThs = thead.querySelectorAll("th");

      for (let i = 0; i < originalThs.length; i++) {
        const width = originalThs[i].offsetWidth;
        stickyThs[i].style.width = `${width}px`;
      }

      thead.style.width = `${table.offsetWidth}px`;
    };

    /**
     * Scroll/resize callback that:
     * - Applies "sticky" class when header scrolls out of view (but table still visible)
     * - Positions the <thead> over the table
     * - Restores original layout when scrolled back up or past the table
     */
    const updateHeader = () => {
      const scrollY = window.scrollY;
      const shouldStick =
        scrollY > theadOffsetTop &&
        scrollY < theadOffsetTop + table.offsetHeight - thead.offsetHeight;

      if (shouldStick) {
        if (!thead.classList.contains("sticky")) {
          thead.classList.add("sticky");
          placeholder.style.display = "table-header-group";
        }

        thead.style.left = `${table.getBoundingClientRect().left}px`;
        thead.style.width = `${table.offsetWidth}px`;
        syncColumnWidths();
      } else {
        thead.classList.remove("sticky");
        thead.style.left = "";
        thead.style.width = "";
        placeholder.style.display = "none";
      }
    };

    // Bind update logic to scroll and resize events
    window.addEventListener("scroll", updateHeader, { passive: true });
    window.addEventListener("resize", updateHeader, { passive: true });

    // Initial run to apply sticky if already scrolled
    updateHeader();
  });
};
