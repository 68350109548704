import axios from "axios";
import { url } from "@/url-helpers";
import { handleError } from "@/utils/error-handling";
import { cloneDeep } from "lodash";

export const state = {
  lastDate: null,
  shareholderRegister: null,
  summaries: {},
};

export const getters = {
  actions: (state) => state.shareholderRegister.shareholder_register_actions,

  currentShareholders: (state) =>
    state.shareholderRegister.shareholders.filter(
      (shareholder) => shareholder.share_count
    ),

  lastAction: (state) => {
    // Avoid mutating original actions
    const actions = [...state.shareholderRegister.shareholder_register_actions];

    return actions.sort((a, b) => b.id - a.id)[0];
  },

  reversedActions: (state) =>
    cloneDeep(state.shareholderRegister.shareholder_register_actions).reverse(),

  shareholderRegister: (state) => state.shareholderRegister,
  shareholders: (state) => state.shareholderRegister?.shareholders || [],

  persistedShareholders: (state) =>
    (state.shareholderRegister?.shareholders || []).filter(
      (shareholder) => shareholder.id
    ),

  summaries: (state) => state.summaries,

  transactions: (state) =>
    state.shareholderRegister?.shareholder_transactions || [],

  saleShareTypeKeys: (state) => {
    const keys = new Set();
    (state.shareholderRegister?.shareholder_transactions || []).forEach(
      (transaction) => {
        keys.add(transaction.share_type);
      }
    );
    return Array.from(keys);
  },
};

export const actions = {
  /**
   * Shareholder Register
   */

  async loadRegister(context) {
    const response = await axios.get(url("/shareholder_register"));

    context.commit("loadRegister", response.data);
  },

  /**
   * Shareholder Register Actions
   */

  async approveAction(context, action) {
    try {
      await axios.post(
        url(`/shareholder_register/actions/${action.id}/statuses`)
      );

      await context.dispatch("loadRegister");
    } catch (error) {
      handleError(error);
    }
  },

  async unapproveAction(context, action) {
    try {
      axios.delete(url(`/shareholder_register/actions/${action.id}/statuses`));

      await context.dispatch("loadRegister");
    } catch (error) {
      handleError(error);
    }
  },

  async destroyAction(context, action) {
    const baseUrl = url("/shareholder_register");
    const actionUrl = `${baseUrl}/actions/${action.id}`;

    try {
      await axios.delete(actionUrl);
      const response = await axios.get(baseUrl);

      context.commit("loadRegister", response.data);
      context.commit("setSummaryByLastActionDate", response.data.summary);
    } catch (error) {
      handleError(error);
    }
  },

  /**
   * Shareholder Series
   */

  async loadSummary(context, date) {
    try {
      const response = await axios.get(
        url("/shareholder_register/date_update"),
        {
          params: {
            action_date: date,
          },
        }
      );
      context.commit("updateSummary", {
        selectedDate: date,
        summary: response.data,
      });
      return true;
    } catch (error) {
      handleError(error);
      return false;
    }
  },

  async loadTransactionHistory(context, payload) {
    let series = payload.series;

    try {
      const response = await axios.get(
        url(
          `/shareholder_register/shareholders/${series.shareholder_id}/transaction_histories`
        ),
        {
          params: {
            max_date: payload.selectedDate,
            start: series.start,
            end: series.end,
          },
        }
      );
      context.commit("updateSeriesHistory", {
        series: payload.series,
        selectedDate: payload.selectedDate,
        history: response.data.history,
      });
    } catch (error) {
      handleError(error);
    }
  },
};

export const mutations = {
  loadRegister(state, shareholderRegister) {
    state.shareholderRegister = shareholderRegister;

    if (state.shareholderRegister.action_dates) {
      // Avoid mutating original dates
      let actionDates = [...state.shareholderRegister.action_dates];

      // (a & 1) - (b & 1) needed for sorting to work in Firefox
      let lastDate = actionDates.sort((a, b) => new Date(b) - new Date(a))[0];

      state.shareholderRegister.action_dates.forEach((actionDate) => {
        if (lastDate == actionDate) {
          state.summaries[actionDate] = state.shareholderRegister.summary;
        } else {
          state.summaries[actionDate] = {};
        }
      });
    }
  },

  setSummaryByLastActionDate(state, summary) {
    // Avoid mutating original dates
    const actionDates = [...state.shareholderRegister.action_dates];

    // (a & 1) - (b & 1) needed for sorting to work in Firefox
    const lastDate = actionDates.sort((a, b) => new Date(b) - new Date(a))[0];

    state.summaries[lastDate] = summary;

    Object.keys(state.summaries).forEach((summaryDate) => {
      if (summaryDate > lastDate) {
        delete state.summaries[summaryDate];
      }
    });
  },

  updateSummary(state, payload) {
    state.summaries[payload.selectedDate] = payload.summary;
  },

  updateSeriesHistory(state, payload) {
    const series = state.summaries[payload.selectedDate].find(
      (localSerie) => localSerie == payload.series
    );
    series["history"] = payload.history;
  },

  updateDocument(state, document) {
    const idx = state.shareholderRegister.documents.findIndex(
      (existing) => existing.id && existing.id === document.id
    );

    if (idx > -1) {
      state.shareholderRegister.documents[idx] = document;
    } else {
      state.shareholderRegister.documents.push(document);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
