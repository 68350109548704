import { useForm } from "@inertiajs/vue3";
import { url } from "@/url-helpers";
import { EventBus } from "@/event-bus";
import { handleError } from "@/utils/error-handling";

export const state = {
  inertiaForms: {},
};

export const getters = {
  getInertiaForm: (state) => (params) => {
    const { group, identifier } = params;

    if (!state.inertiaForms[group] || !state.inertiaForms[group][identifier]) {
      return;
    }

    return state.inertiaForms[group][identifier];
  },

  getInertiaFormErrors: (state) => (params) => {
    const { group, identifier, field } = params;

    const form = getters.getInertiaForm(state)({
      group: group,
      identifier: identifier,
    });

    const fieldIdentifier = field || identifier;

    const errorsCount = Object.keys(form?.errors || {}).length;

    try {
      // Fixme why is this needed? It won't work if multiple fields check the same form.
      if (errorsCount === 1) {
        return form.errors[fieldIdentifier][0];
      }
    } catch {
      return form.errors[fieldIdentifier];
    }

    if (errorsCount > 1) {
      return form.errors[fieldIdentifier];
    }

    return;
  },

  getInertiaFormGroupErrors: (state) => (group) => {
    if (!Array.isArray(group)) {
      group = [group];
    }

    let errorMessages = [];

    for (const item of group) {
      if (!state.inertiaForms[item]) {
        continue;
      }

      const forms = state.inertiaForms[item];

      for (const key in forms) {
        const form = forms[key];

        if (form.errors) {
          for (const errorKey in form.errors) {
            errorMessages.push(
              `${errorKey} ${form.errors[errorKey].join(" ")}`
            );
          }
        }
      }
    }

    if (errorMessages.length === 0) {
      return;
    }

    errorMessages = errorMessages.filter(
      (item, index) => errorMessages.indexOf(item) === index
    );

    return errorMessages;
  },

  getInertiaFormSuccessful: (state) => (params) => {
    const { group, identifier } = params;

    const form = getters.getInertiaForm(state)({
      group: group,
      identifier: identifier,
    });

    if (!form) {
      return;
    }

    if (form.wasSuccessful === false && form.processing === true) {
      // Null needed for frontend component so it doesnt show state as incorrect
      return null;
    }

    return form.wasSuccessful;
  },

  getInertiaFormGroupAnyProcessing: (state) => (group) => {
    if (!Array.isArray(group)) {
      group = [group];
    }

    for (const item of group) {
      if (!state.inertiaForms[item]) {
        return false;
      }

      const forms = state.inertiaForms[item];

      for (const key in forms) {
        if (forms[key].processing === true) {
          return true;
        }
      }
    }

    return false;
  },
};

export const actions = {
  submitInertiaForm({ dispatch, state }, params) {
    const { group, method, path, formData, identifier, only } = params;

    if (method === null) {
      handleError("Method not provided");
      return;
    }

    if (path === null) {
      handleError("Path not provided");
      return;
    }

    dispatch("setForm", {
      group: group,
      identifier: identifier,
      formData: formData ?? {},
    });

    EventBus.emit(`${group}-submitting`, "in_progress");

    state.inertiaForms[group][identifier].submit(method, url(path), {
      preserveScroll: true,
      only: only || [],

      onSuccess: () => {
        EventBus.emit(`${group}-submitting`, "completed");
      },

      onError: (errors) => {
        handleError(errors);
        EventBus.emit(`${group}-submitting`, "failed");
      },
    });
  },

  setForm({ commit }, params) {
    const { group, identifier, formData } = params;

    if (group === null) {
      handleError("Group not provided");
      return;
    }

    if (identifier === null) {
      handleError("Identifier not provided");
      return;
    }

    commit("setInertiaForm", {
      group: group,
      identifier: identifier,
      data: formData,
    });
  },
};

export const mutations = {
  setInertiaForm(state, params) {
    const { group, identifier, data } = params;

    if (!state.inertiaForms[group]) {
      state.inertiaForms[group] = {};
    }

    state.inertiaForms[group][identifier] = useForm(data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
