<template>
  <div class="table-responsive">
    <table class="table table-bordered">
      <tbody>
        <tr>
          <th>
            {{ $t("activerecord.models.document.one") }}
          </th>

          <td>
            <document-link
              :document-id="document.id"
              :filename="document.filename"
            />
          </td>
        </tr>

        <tr>
          <th>
            {{ $t("activerecord.attributes.signature.created_at") }}
          </th>

          <td>
            {{ signatureSentAt }}
          </td>
        </tr>

        <tr v-if="signatureRequestedBy">
          <th>
            {{
              $t("activerecord.attributes.document.signature_requested_by_id")
            }}
          </th>

          <td>
            {{ signatureRequestedBy }}
          </td>
        </tr>

        <tr>
          <th>
            {{ $t("models.document.signature_solutions") }}
          </th>

          <td>
            <be-badge
              v-for="solution in document.allowed_signature_types || []"
              :key="`signature-solution-${solution}`"
              v-be-tooltip="
                $t(
                  `models.document.signature_solution_list.${solution}.description`
                )
              "
              variant="primary"
              class="mr-1"
            >
              {{
                $t(`models.document.signature_solution_list.${solution}.title`)
              }}
            </be-badge>
          </td>
        </tr>

        <tr v-if="hasExternalSignatories">
          <th class="col-shrink">
            <span class="mr-1">
              {{
                $t(
                  "components.documents.signature_case_metadata.allowed_external_access"
                )
              }}
            </span>

            <i
              v-be-tooltip="
                $t(
                  'components.documents.signature_case_metadata.allowed_external_access_tooltip'
                )
              "
              class="fal fa-question-circle"
            />
          </th>

          <td>
            <template v-if="document.external_access_end_at">
              {{ $t("components.documents.signature_case_metadata.yes_until") }}
              {{
                $d(new Date(document.external_access_end_at), "humanReadable")
              }}.
            </template>

            <template
              v-else-if="!document.signed_at && document.allow_external_access"
            >
              {{
                $t(
                  "components.documents.signature_case_metadata.after_document_signed"
                )
              }}
            </template>

            <template v-else>
              {{ $t("components.documents.signature_case_metadata.no") }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    document: {
      type: Object,
      required: true,
    },
  },

  computed: {
    hasExternalSignatories() {
      return !!this.document.signatures.find((signature) => !signature.user_id);
    },

    signatureRequestedBy() {
      const membership = this.getMembershipByUserId(
        this.document.signature_requested_by_id
      );

      return membership?.user?.name;
    },

    signatureSentAt() {
      const signature = this.document.signatures[0];
      if (signature) {
        return this.$d(this.parseDate(signature.created_at), "dateTime");
      }
      return null;
    },
  },
};
</script>
