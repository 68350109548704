import { mapGetters } from "vuex";

/**
 * Shortcuts to check if editor is in agenda or minutes mode
 */
export default {
  computed: {
    ...mapGetters({
      materialType: "material/editorType",
      meeting: "material/meeting",
      material: "material/material",
      attendances: "attendances/attendances",
    }),

    isAgenda() {
      return this.materialType === "agenda";
    },

    isMinutes() {
      return this.materialType === "minutes";
    },

    isTemplate() {
      return this.materialType === "template";
    },

    isSealed() {
      return this.material.sealed;
    },

    useTiptap() {
      return this.material.use_tiptap_text;
    },

    currentState() {
      return this.meeting.process.active_state;
    },

    inReview() {
      return this.meeting.process.active_state == "review";
    },

    minutesReviewStarted() {
      return this.material.id && this.material.secretary_done;
    },

    hashtagsToValues() {
      const data = {
        secretary: [],
        reviewer: [],
        chairman: [],
        reviewer_including_chairman: [],
        next_meeting: [],
        ceo: [],
        financialmanager: [],
      };

      // We expect the `hashtag_values` to hold the same 7 keys as `const data`
      if (
        this.material.sealed &&
        Object.keys(this.material.hashtag_values).sort == Object.keys(data).sort
      ) {
        return this.material.hashtag_values;
      }

      this.attendances.forEach((attendance) => {
        if (attendance.secretary) {
          data["secretary"].push(attendance.name);
        }

        if (attendance.reviewer) {
          data["reviewer"].push(attendance.name);
          data["reviewer_including_chairman"].push(attendance.name);
        }

        if (attendance.function === "chairman") {
          data["chairman"].push(attendance.name);
          data["reviewer_including_chairman"].push(attendance.name);
        }
      });

      if (this.meeting?.next_start_at) {
        data["next_meeting"].push(
          this.$i18n.d(
            new Date(this.meeting?.next_start_at),
            "humanReadableWithTime"
          )
        );
      }

      data["ceo"].push(
        ...this.$currentCompany.memberships
          .filter((membership) => membership.role === "ceo")
          .map((membership) => membership.user.name)
      );

      data["financialmanager"].push(
        ...this.$currentCompany.memberships
          .filter((membership) => membership.role === "cfo")
          .map((membership) => membership.user.name)
      );

      return data;
    },
  },
};
